<template>
  <div>
    <div class="container mx-auto px-4">
      <div class="grid grid-cols-1 gap-4 my-8">
        <div v-for="item in items" :key="item.title" class="space-y-2">
          <template v-if="item.route">
            <router-link
              :to="item.route"
              class="bg-black rounded-md text-white font-bold w-full mx-auto py-2 flex items-center justify-center"
            >
              <div class="p-4">
                <h2 class="font-bold">
                  {{ $t(item.title) }}
                </h2>
                <div
                  class="bg-gray-700 p-1 text-xs mt-2 text-white font-bold rounded-full px-4"
                >
                  {{ item.ar }}
                </div>
              </div>
            </router-link>
          </template>

          <template v-else>
            <a
              :href="item.url"
              class="bg-black rounded-md text-white font-bold w-full mx-auto py-2 flex items-center justify-center"
            >
              <div class="p-4">
                <h2 class="font-bold">
                  {{ $t(item.title) }}
                </h2>
                <div
                  class="bg-gray-700 p-1 text-xs mt-2 text-white font-bold rounded-full px-4"
                >
                  {{ item.ar }}
                </div>
              </div>
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      title: "荔枝碗船廠 AR 列表",
      items: [
        {
          name: "風帆漁船",
          title: "fishing_sailboats",
          route: "/fishing-sailboats",
          ar: "圖像追蹤",
        },
        {
          name: "機動漁船",
          title: "mechanised_fishing_boats",
          route: "/mechanised-fishing-boats",
          ar: "圖像追蹤",
        },
        {
          name: "𠝹木機",
          title: "wood_machine",
          route: "wood-machine",
          ar: "地標AR",
        },
        {
          name: "船廠-正門",
          title: "shipyard_front",
          route: "/shipyard/front",
          ar: "漫遊AR",
        },
        {
          name: "船廠-側門1",
          title: "shipyard_side_1",
          route: "/shipyard/side-1",
          ar: "漫遊AR",
        },
        {
          name: "船廠-側門2",
          title: "shipyard_side_2",
          route: "/shipyard/side-2",
          ar: "漫遊AR",
        },
      ],
    };
  },
  created() {
    this.$route.meta.title = this.title;
    document.title = this.$route.meta.title;
  },
};
</script>
