<template>
  <div class="fixed inset-0">
    <div class="container mx-auto px-4">
      <div class="grid grid-cols-1 gap-4 mt-8">
        <div v-for="item in items" :key="item.title" class="space-y-2">
          <template v-if="item.route">
            <router-link
              :to="item.route"
              class="bg-black rounded-md text-white font-bold w-full mx-auto py-2 flex items-center justify-center"
            >
              <div class="p-4">
                <h2 class="font-bold">
                  {{ $t(item.title) }}
                </h2>
              </div>
            </router-link>
          </template>

          <template v-else>
            <a
              :href="item.url"
              class="bg-black rounded-md text-white font-bold w-full mx-auto py-2 flex items-center justify-center"
            >
              <div class="p-4">
                <h2 class="font-bold">
                  {{ $t(item.title) }}
                </h2>
              </div>
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "荔枝碗船廠 AR 列表",
      items: [
        {
          name: "風帆漁船",
          title: "fishing_sailboats",
          route: "/fishing-sailboats",
        },
        {
          name: "機動漁船",
          title: "mechanised-fishing-boats",
          route: "/mechanised-fishing-boats",
        },
        {
          name: "𠝹木機",
          title: "wood-machine",
          route: "wood-machine",
        },
        {
          name: "船廠-正門",
          title: "shipyard-front",
          route: "/shipyard/front",
        },
        {
          name: "船廠-側門1",
          title: "shipyard-side-1",
          route: "/shipyard/side-1",
        },
        {
          name: "船廠-側門2",
          title: "shipyard-side-2",
          route: "/shipyard/side-2",
        },
      ],
    };
  },
  created() {
    this.$route.meta.title = this.title;
    document.title = this.$route.meta.title;
  },
};
</script>
