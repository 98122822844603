import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'
import Collection from '../views/scene-list.vue'
import FishingSailboats from '../views/ar/fishing-sailboats/scene.vue'
import WoodMachine from '../views/ar/wood-machine/index.vue'
import WoodMachineScene from '../views/ar/wood-machine/scene.vue'
import MechanisedBoat from '../views/ar/mechanised-boat/scene.vue'
import ShipyardFront from '../views/ar/shipyard/front/index.vue'
import ShipyardFrontScene from '../views/ar/shipyard/front/scene.vue'
import ShipyardSide1 from '../views/ar/shipyard/side-1/index.vue'
import ShipyardSide1Scene from '../views/ar/shipyard/side-1/scene.vue'
import ShipyardSide2 from '../views/ar/shipyard/side-2/index.vue'
import ShipyardSide2Scene from '../views/ar/shipyard/side-2/scene.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/collection',
    name: 'collection',
    component: Collection
  },
  {
    path: '/fishing-sailboats',
    name: 'fishing-sailboats',
    component: FishingSailboats 
  },
   {
    path: '/wood-machine',
    name: 'wood-machine',
    component: WoodMachine
  },
  {
    path: '/wood-machine/scene',
    name: 'wood-machine-scene',
    component: WoodMachineScene
  },
   {
    path: '/mechanised-fishing-boats',
    name: 'mechanised-fishing-boats',
    component: MechanisedBoat 
  },
   {
    path: '/shipyard/front',
    name: 'shipyard-front',
    component: ShipyardFront 
  },
  {
    path: '/shipyard/front/scene',
    name: 'shipyard-front-scene',
    component: ShipyardFrontScene
  },
  {
    path: '/shipyard/side-1',
    name: 'shipyard-side-1',
    component: ShipyardSide1 
  },
  {
    path: '/shipyard/side-1/scene',
    name: 'shipyard-side-1-scene',
    component: ShipyardSide1Scene
  },
    {
    path: '/shipyard/side-2',
    name: 'shipyard-side-2',
    component: ShipyardSide2
  },
  {
    path: '/shipyard/side-2/scene',
    name: 'shipyard-side-2-scene',
    component: ShipyardSide2Scene
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
